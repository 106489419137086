<template>
  <div class="container">
    <b-overlay :show="loading" rounded="sm">
      <form
        @submit.prevent="miembroId ? handleUpdateMiembro() : handleAddMiembro()"
      >
        <div class="row col-12">
          <div class="col-6">
            <b-form-group label="Nombre">
              <b-form-input v-model="miembro.nombre" type="text" required>
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Posicion">
              <b-form-input
                v-model.number="miembro.posicion"
                type="text"
                required
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Cargo">
              <b-form-input v-model="miembro.cargo" type="text" required>
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Cargo inglés">
              <b-form-input v-model="miembro.cargoIngles" type="text" required>
              </b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row col-12">
          <label>Descripcion Resumida</label>
          <vue-editor v-model="miembro.descripcion_resumida"></vue-editor>
        </div>
        <div class="row col-12">
          <label>Descripcion Resumida Inglés</label>
          <vue-editor v-model="miembro.descripcion_resumidaIngles"></vue-editor>
        </div>

        <div class="row col-12 mt-3">
          <label>Descripcion Extendida</label>
          <vue-editor v-model="miembro.descripcion_extendida"></vue-editor>
        </div>
        <div class="row col-12 mt-3">
          <label>Descripcion Extendida Inglés</label>
          <vue-editor v-model="miembro.descripcion_extendidaIngles"></vue-editor>
        </div>
        <div class="row col-12">
          <h5 class="mt-3">Imagen</h5>
        </div>
        <div class="row col-12">
          <b-form-file
            :state="Boolean(imagen)"
            placeholder="Arrastra una imagen aqui..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .jpeg, .png"
            @input="imageUploaded($event)"
          ></b-form-file>
          <div v-if="miembro.imagen && miembro.imagen.hasOwnProperty('path')">
            <b-icon icon="card-image"></b-icon>
            <a :href="miembro.imagen.url" target="_blank" class="ml-1"
              >Imagen Actual</a
            >
          </div>
        </div>
        <div class="row col-12">
          <div>
            <img
              v-if="localImage"
              :src="localImage"
              alt="responsive image"
              width="300px"
              height="300px"
            />
          </div>
        </div>
        <div class="row col-12">
          <h5 class="mt-3">Redes Sociales</h5>
        </div>
        <div class="row col-12">
          <div class="col-6">
            <b-form-group label="Facebook">
              <b-form-input
                v-model="miembro.redes_sociales.facebook"
                type="text"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Instagram">
              <b-form-input
                v-model="miembro.redes_sociales.instagram"
                type="text"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="LinkedIn">
              <b-form-input
                v-model="miembro.redes_sociales.linkedin"
                type="text"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Correo">
              <b-form-input
                type="email"
                v-model="miembro.redes_sociales.correo"
              >
              </b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row col-12 d-flex align-items-center mt-2">
          <b-button type="submit" variant="primary" size="md">
            {{ miembroId ? "Actualizar" : "Crear" }}
          </b-button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    miembroId: {
      type: String,
      required: false,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      localImage: null,
      loading: false,
      imagen: null,
      miembro: {
        nombre: "",
        cargo: "",
        cargoIngles:'',
        descripcion_resumida: "",
        descripcion_resumidaIngles:'',
        descripcion_extendida: "",
        descripcion_extendidaIngles:'',
        imagen: null,
        posicion: "",
        redes_sociales: {
          facebook: "",
          instagram: "",
          linkedin: "",
          correo: "",
        },
      },
    };
  },

  methods: {
    ...mapActions({
      addMiembro: "equipo/addMiembro",
      getMiembro: "equipo/getMiembro",
      updateMiembro: "equipo/updateMiembro",
    }),
    imageUploaded(imagen) {
      let url = URL.createObjectURL(imagen);
      this.localImage = url;
      this.miembro.imagen = imagen;
    },
    async handleAddMiembro() {
      this.loading = true;
      const res = await this.addMiembro(this.miembro);
      if (res) {
        this.$swal({
          title: "Miembro Agregado",
          text: "El miembro ha sido agregado correctamente",
          icon: "success",
          button: "Ok",
        });
      } else {
        this.$swal({
          title: "Error",
          text: "No se pudo agregar el miembro",
          icon: "error",
          button: "Ok",
        });
      }
      this.loading = false;
      this.$router.push("/admin/equipo");
    },
    async handleUpdateMiembro() {
      this.loading = true;
      const res = await this.updateMiembro(this.miembro);
      if (res) {
        this.$swal({
          title: "Miembro Actualizado",
          text: "El miembro ha sido actualizado correctamente",
          icon: "success",
          button: "Ok",
        });
      } else {
        this.$swal({
          title: "Error",
          text: "No se pudo actualizar el miembro",
          icon: "error",
          button: "Ok",
        });
      }
      this.loading = false;
      this.$router.push("/admin/equipo");
    },
  },
  computed: {
    ...mapGetters({
      miembrodb: "equipo/miembro",
    }),
  },
  async mounted() {
    if (this.miembroId) {
      await this.getMiembro(this.miembroId);
      this.miembro = JSON.parse(JSON.stringify(this.miembrodb));
    }
  },
};
</script>

<style scoped>
.quillWrapper {
  width: 100%;
  height: 100%;
}
</style>
